import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/all-in-one-solar-street-light/18(1).jpg"
import img2 from "../../images/all-in-one-solar-street-light/18(2).jpg"
import img3 from "../../images/all-in-one-solar-street-light/18(3).jpg"
import img4 from "../../images/all-in-one-solar-street-light/18(4).jpg"

import descImg1 from "../../images/all-in-one-solar-street-light/123.jpeg"
import descImg2 from "../../images/all-in-one-solar-street-light/124.jpg"
import descImg3 from "../../images/all-in-one-solar-street-light/product-features.png"

import allInoneSolarStreetLightSpecSheet from "../../images/all-in-one-solar-street-light/specSheet.svg"

const IntegratedSolarPage = () => {
  return (
    <Layout home={false}>
      <SEO title="All in One Solar Street Light" />
      <ProductPage
        productTitle="All in One Solar Street Light"
        showcase={
          <>
            <ul>
              <li>140 W Efficacy</li>
              <li>Available in 2700-6500 K CCT</li>
              <li>Circular pattern PC/PMMA Optical enclosure</li>
              <li>ISO 9001:2008 (manufacturing)</li>
              <li>Dusk to Dawn feature</li>
              <li>5 years warranty</li>
            </ul>
            <ul>
              <li>Models Available:</li>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    padding: "0.2rem 0.7rem",
                    borderStyle: "solid",
                    borderColor: "#203864",
                    borderWidth: "0.1rem",
                  }}
                >
                  30 W
                </div>
                <div
                  style={{
                    padding: "0.2rem 0.7rem",
                    borderStyle: "solid",
                    borderColor: "#203864",
                    borderWidth: "0.1rem",
                  }}
                >
                  25 W
                </div>
                <div
                  style={{
                    padding: "0.2rem 0.7rem",
                    borderStyle: "solid",
                    borderColor: "#203864",
                    borderWidth: "0.1rem",
                  }}
                >
                  18 W
                </div>
                <div
                  style={{
                    padding: "0.2rem 0.7rem",
                    borderStyle: "solid",
                    borderColor: "#203864",
                    borderWidth: "0.1rem",
                  }}
                >
                  15 W
                </div>
                <div
                  style={{
                    padding: "0.2rem 0.7rem",
                    borderStyle: "solid",
                    borderColor: "#203864",
                    borderWidth: "0.1rem",
                  }}
                >
                  12 W
                </div>
                <div
                  style={{
                    padding: "0.2rem 0.7rem",
                    borderStyle: "solid",
                    borderColor: "#203864",
                    borderWidth: "0.1rem",
                  }}
                >
                  7 W
                </div>
              </div>
            </ul>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{
                  backgroundColor: "#203864",
                  marginTop: "1rem",
                  color: "white",
                  textDecoration: "none",
                  padding: "0.5rem 1rem",
                  fontFamily: "Helvetica",
                }}
              >
                Enquire Now
              </Link>
            </div>
          </>
        }
        showcaseImages={[img1, img2, img3, img4]}
        description={
          <div>
            <p style={{ fontSize: "1.2rem" }}>
              All in one streetlight are new and extremely versatile solar
              powered led street lights, This integrated Solar street light that
              is compact and easy to install. HSL Integrated Solar Street Light
              is made in India with High Efficiency Mono Crystalline Solar PV
              Module, Lithium Ferro Phosphate battery with Inbuilt BMS
              technology, High Efficiency LED with MPPT Solar Charge Controller
              & Aluminum Extruded body. Integrated SSL is specially designed
              with Panasonic PIR Motion Sensor to control the Light ON/Off
              during any activity near the light which increases the backup time
              and life of LiFePO4 battery.
            </p>
            {/* <p>
              <b>Product Features:</b>
            </p>
            <ol>
              <li style={{ marginTop: "1rem" }}>
                All-in-one design: solar panel, lifepo4 battery, led light,
                intelligent controller and aluminum case all in one, easy
                installing, convenient shipping.
              </li>
              <li style={{ marginTop: "1rem" }}>
                Science and patent design, ultra-thin shape to anti-wind level
                70m/sec
              </li>
              <li style={{ marginTop: "1rem" }}>
                Adopt brand lifepo4 battery cell and combined with intelligent
                software BMS,which is the same working principle with Tesla
                battery management system, to extend battery lifespan to be more
                than 10 years.
              </li>
              <li style={{ marginTop: "1rem" }}>
                Intelligent controller with MPPT charge technology and microwave
                sensor/PIR sensor (Zigbee controller and GPRS/3G/4G
                communication system for option )
              </li>
              <li style={{ marginTop: "1rem" }}>
                Different to tradition all-in-one solar lights, the battery pack
                is easily replaced.
              </li>
              <li style={{ marginTop: "1rem" }}>
                Adopt monocrystalline silicon solar panel, higher charging
                efficiency
              </li>
            </ol> */}
            <div
              className="product-description-heading"
              style={{ marginTop: "2rem" }}
            >
              Product Features
            </div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <img
                src={descImg3}
                style={{ maxWidth: "90%", margin: "0 auto", maxHeight: "60rem" }}
              />
            </div>
            <div
              style={{
                marginTop: "3rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <iframe
                // width="560"
                // height="315"
                style={{
                  width: "90%",
                  margin: "1rem auto 3rem auto",
                  height: "500px",
                }}
                margin="0 auto"
                src="https://www.youtube.com/embed/RwxXTBVmGds?autoplay=1"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <img
                src={descImg1}
                style={{ maxWidth: "90%", margin: "0 auto" }}
                alt="IOT Based Solar Street Light System"
                title="IOT Based Solar Street Light System"
              />
              <img
                src={descImg2}
                style={{ maxWidth: "90%", margin: "2rem auto" }}
                alt="IOT operating moder"
                title="IOT operating moder"
              />
              <img />
            </div>
          </div>
        }
        technicalDetails={
          <div style={{ width: "180%", marginLeft: "-40%" }}>
            <img
              src={allInoneSolarStreetLightSpecSheet}
              style={{ width: "100%" }}
            />
          </div>
        }
      />
    </Layout>
  )
}

export default IntegratedSolarPage
